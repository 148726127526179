/*!
 * Fonts Imports
 *
 * Fonts used in the Cengage Learning site. Font face declarations are in
 * separate files to keep things cleaner
 */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Open Sans Light"), local("OpenSans-Light"), url("../../../fonts/open-sans/Light/OpenSans-Light.woff2") format("woff2"), url("../../../fonts/open-sans/Light/OpenSans-Light.woff") format("woff"); }

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Open Sans Regular"), local("OpenSans-Regular"), url("../../../fonts/open-sans/Regular/OpenSans-Regular.woff2") format("woff2"), url("../../../fonts/open-sans/Regular/OpenSans-Regular.woff") format("woff"); }

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"), url("../../../fonts/open-sans/SemiBold/OpenSans-SemiBold.woff2") format("woff2"), url("../../../fonts/open-sans/SemiBold/OpenSans-SemiBold.woff") format("woff"); }

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Open Sans Bold"), local("OpenSans-Bold"), url("../../../fonts/open-sans/Bold/OpenSans-Bold.woff2") format("woff2"), url("../../../fonts/open-sans/Bold/OpenSans-Bold.woff") format("woff"); }

@font-face {
  font-family: "summer";
  font-display: swap;
  src: local("summer"), url("../../../fonts/summer/Summer-Font-Regular.woff") format("woff"); }

@font-face {
  font-family: "summer-italic";
  font-display: swap;
  src: local("summer-italic"), url("../../../fonts/summer/Summer-Font-Regular-Italic.woff") format("woff"); }

@font-face {
  font-family: "summer-bold";
  font-display: swap;
  src: local("summer-bold"), url("../../../fonts/summer/Summer-Font-Bold.woff") format("woff"); }

@font-face {
  font-family: "summer-bold-italic";
  font-display: swap;
  src: local("summer-bold-italic"), url("../../../fonts/summer/Summer-Font-Bold-Italic.woff") format("woff"); }

@font-face {
  font-family: "summer-light";
  font-display: swap;
  src: local("summer-light"), url("../../../fonts/summer/Summer-Font-Light.woff") format("woff"); }

@font-face {
  font-family: "summer-light-italic";
  font-display: swap;
  src: local("summer-light-italic"), url("../../../fonts/summer/Summer-Font-Light-Italic.woff") format("woff"); }

@font-face {
  font-family: "font-awesome";
  font-display: block;
  src: local("font-awesome"), local("fontawesome"), local("FontAwesome"), url("../../../fonts/font-awesome/fontawesome-webfont.woff2") format("woff2"), url("../../../fonts/font-awesome/fontawesome-webfont.woff") format("woff"), url("../../../fonts/font-awesome/fontawesome-webfont.ttf") format("truetype"), url("../../../fonts/font-awesome/fontawesome-webfont.eot") format("embedded-opentype"), url("../../../fonts/font-awesome/fontawesome-webfont.svg") format("svg"); }

@font-face {
  font-family: 'Trade Gothic';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Trade Gothic Bold"), local("TradeGothic-Bold"), url("../../../fonts/trade-gothic/TradeGothicLT-BoldCondTwenty.woff2") format("woff2"), url("../../../fonts/trade-gothic/TradeGothicLT-BoldCondTwenty.woff") format("woff"), url("../../../fonts/trade-gothic/TradeGothicLT-BoldCondTwenty.ttf") format("truetype"), url("../../../fonts/trade-gothic/TradeGothicLT-BoldCondTwenty.eot") format("embedded-opentype"); }

@font-face {
  font-family: 'Trade Gothic';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("Trade Gothic BoldItalic"), local("TradeGothic-BoldItalic"), url("../../../fonts/trade-gothic/TradeGothicLT-BoldCondTwentyItalic.woff2") format("woff2"), url("../../../fonts/trade-gothic/TradeGothicLT-BoldCondTwentyItalic.woff") format("woff"), url("../../../fonts/trade-gothic/TradeGothicLT-BoldCondTwentyItalic.ttf") format("truetype"), url("../../../fonts/trade-gothic/TradeGothicLT-BoldCondTwentyItalic.eot") format("embedded-opentype"); }

/*!
 * Variables SCSS
 *
 * General variables used throughout the SCSS for Cengage Learning
 */
/*!
 * Contrast SCSS
 *
 * Calculates a numeric representation of the contrast between two colors, and
 * provides a function for picking the best contrasting color from a list
 */
/*!
 * Math SCSS
 *
 * Various potentially useful SCSS functions for mathematical calculations
 */
/*!
 * BEM (Block, Element, Modifier) Mixins SCSS
 *
 * Custom mixins for handling BEM-style class naming. Have tried this using existing
 * third-party SCSS libraries, but the way we apply variations in Hippo-based site
 * structure, those make a mess of nesting of things. The goal of these mixins is to
 * remove some of the ugly nesting issues while still making it easy to apply BEM-style
 * class naming (which has some good benefits)
 */
/*!
 * Buttons Mixins SCSS
 *
 * Styling for special button (and button-like) controls
 */
/*!
 * Component Mixins SCSS
 *
 * Styling to normalize all Hippo components (and component-like elements)
 */
/*!
 * Device Frame Image Layers SCSS
 *
 * Mixins for applying those odd little device frame images to things
 */
/*!
 * Dropdown Menu Button
 *
 * Styling for a dropdown menu (button version)
 */
/*!
 * Dropdown Menu List
 *
 * Styling for a dropdown menu (list version)
 */
/*!
 * Flexbox Mixins SCSS
 *
 * Styling to support flexbox in browser that support it, and graceful fallbacks
 * for any non-flexbox browsers
 */
/*!
 * Graphics (Images and Videos) SCSS
 *
 * Mixins to set properties on images and videos
 */
/*!
 * Links SCSS
 *
 * Styling for links with hover and active behaviors
 */
/*!
 * Lists SCSS
 *
 * Helper mixins for styling lists consistently
 */
/*!
 * Modals SCSS
 *
 * Helper mixins for styling modals consistently
 */
/*!
 * Nav Bar Mixins SCSS
 *
 * Styling for a simple nav bar of links; used for Simple Nav and Product Section Simple Nav
 */
/*!
 * Panel Mixins
 *
 * Bootstrap's panel classes are oddly complicated to override, so
 * here's some new mixins based on them to make it easier
 */
/*!
 * Promo Layouts Mixins SCSS
 *
 * Styling for a single promo with content; used for Promo Component
 */
/*!
 * Responsive Square Mixins SCSS
 *
 * Apply to something you want to be responsively square
 */
/*!
 * Sprites SCSS
 *
 * Helper mixins for styling SVG sprites (either inline or as img tags)
 */
/*
* $color parameter can by a string or a map: 
* - $color: will add the color tho the svg > path by default;
* - $color: will support a Sass Map: (svgElementA:colorA, svgElementB:colorB)
* 															like: (polygon: white, cirle: white)
*/
/*!
 * Tabbed Layouts Mixins SCSS
 *
 * Styling for a tabbed layout
 */
/*!
 * Text Variants SCSS
 *
 * The following are mixins or placeholders for standardizing the display of
 * fonts across all parts and components of the site. No CSS related to font
 * declarations should be set anywhere else in the CSS; these mixins and
 * placeholders should always be used to keep consistency
 *
 * Try to place font declarations as close as possible to the text element to
 * minimize complex nested em calculations. Each component is reset to basetextsize to
 * help with this.
 */
/*!
 * Variables SCSS
 *
 * General variables used throughout the SCSS for Cengage Learning
 */
/*!
 * Links SCSS
 *
 * Styling for links with hover and active behaviors
 */
/*!
 * Text Variants SCSS
 *
 * The following are mixins or placeholders for standardizing the display of
 * fonts across all parts and components of the site. No CSS related to font
 * declarations should be set anywhere else in the CSS; these mixins and
 * placeholders should always be used to keep consistency
 *
 * Try to place font declarations as close as possible to the text element to
 * minimize complex nested em calculations. Each component is reset to basetextsize to
 * help with this.
 */
/*!
 * Video Layouts Mixins SCSS
 *
 * Styling for a single video with content; used for Video (Single) and Product Section Video (Single)
 */
/*!
 * Rich Text Area
 *
 * Placeholders to set up all the defaults for use in a rich text area
 */
.ceng-breadcrumb {
  width: 100%;
  position: relative;
  display: block;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1em; }
  .ceng-breadcrumb:before, .ceng-breadcrumb:after {
    display: table;
    content: " "; }
  .ceng-breadcrumb:after {
    clear: both; }
  .ceng-breadcrumb:first-child {
    margin-top: 0; }
  .ceng-breadcrumb:last-child {
    margin-bottom: 0; }
  .ceng-breadcrumb_container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    max-width: 1130px;
    min-height: 36px;
    margin: auto; }
    .ceng-breadcrumb_container ul {
      list-style: none;
      padding-left: 0;
      margin: 0; }
    .ceng-breadcrumb_container li {
      display: block;
      float: left;
      font-size: 14px;
      line-height: 20px; }
      .ceng-breadcrumb_container li a {
        display: inline-block; }
        .ceng-breadcrumb_container li a, .ceng-breadcrumb_container li a:visited {
          background-color: transparent;
          color: #337ab7;
          text-decoration: none; }
        .ceng-breadcrumb_container li a:hover, .ceng-breadcrumb_container li a:focus {
          color: #64a0d3; }
        .ceng-breadcrumb_container li a:active {
          color: #23527c; }
    .ceng-breadcrumb_container li.dark {
      display: block;
      float: left;
      font-family: "Open Sans", sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px; }
      .ceng-breadcrumb_container li.dark a {
        display: inline-block;
        text-decoration: underline;
        color: #ffffff; }
    .ceng-breadcrumb_container li.light {
      display: block;
      float: left;
      font-family: "Open Sans", sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px; }
      .ceng-breadcrumb_container li.light a {
        display: inline-block;
        text-decoration: underline;
        color: #006298; }
    .ceng-breadcrumb_container .breadcrumb-list {
      padding-bottom: 8px; }
    .ceng-breadcrumb_container .breadcrumb-item {
      padding-top: 8px;
      display: -webkit-box !important;
      display: -webkit-flex !important;
      display: -ms-flexbox !important;
      display: flex !important;
      white-space: nowrap; }
    @media (max-width: 1130px) {
  .ceng-breadcrumb_container {
    margin-left: 10px;
    margin-right: 10px; } }
    @media (max-width: 992px) {
  .ceng-breadcrumb_container {
    margin-left: 24px;
    margin-right: 24px; } }
    @media (max-width: 600px) {
  .ceng-breadcrumb--hide-on-mobile {
    display: none; } }
